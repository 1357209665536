import { render, staticRenderFns } from "./content.vue?vue&type=template&id=51309b70&"
import script from "./content.vue?vue&type=script&lang=js&"
export * from "./content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTransition: require('/app/components/ui/transition.vue').default})
