//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'PageHeader',
    props: {
        bigTitle: {
            type: Boolean,
            default: false,
        },
        isContact: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        title: {
            required: true,
            type: String,
        },
    },
};
