//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from 'js-cookie';

export default {
    name: 'CookieConsent',
    data() {
        return {
            consent: {
                accept: this.$t('consent.accept'),
                functional: this.$t('consent.functional'),
                noAutoOptIn: true,
                prefix: '23G_site',
                refuse: this.$t('consent.refuse'),
                sameSite: 'Strict',
                secure: true,
                settings: this.$t('consent.settings'),
                title: this.$t('consent.title'),
                tracking: this.$t('consent.tracking'),
                types: ['functional', 'tracking'],
            },
            selectedTypes: ['functional'],
            showConsentBar: false,
        };
    },
    computed: {
        consentTypes() {
            return this.consent.types || ['functional', 'tracking'];
        },

        showConsentTypesDefault() {
            return this.consent.showConsentTypesDefault || true;
        },

        noAutoOptIn() {
            return this.consent.noAutoOptIn || false;
        },

        noReadOnly() {
            return this.consent.noReadOnly || false;
        },

        sameSite() {
            return typeof this.consent.sameSite === 'undefined' ? 'Lax' : this.consent.sameSite;
        },

        isConsentAlreadySet() {
            const prefix = this.consent.prefix || '';
            const consentIsset = typeof Cookies.get(`${prefix}consent`) !== 'undefined';
            const oldTypes = (Cookies.get(`${prefix}consentOriginal`) || '').split('|');
            const oldTypesMatchesCurrentTypes = this.consentTypes.every((currentType) =>
                oldTypes.includes(currentType),
            );
            return consentIsset && oldTypesMatchesCurrentTypes;
        },

        secure() {
            if (this.sameSite === 'none') return true;
            return typeof this.consent.secure === 'undefined'
                ? this.consent.protocol === 'https:'
                : this.consent.secure;
        },
    },
    watch: {
        consent() {
            window.Consent = this.consent;
        },
    },
    mounted() {
        if (!window.Consent) {
            window.Consent = this.consent;
            window.Consent.hasOptIn = this.consentHasOptIn;

            document.dispatchEvent(new CustomEvent('consentInited'));
        }

        this.$nextTick(() => {
            if (!this.isConsentAlreadySet) this.toggleConsentBar();
            if (!this.noAutoOptIn && !this.isConsentAlreadySet) {
                this.setCookies(this.consentTypes);
            }
        });
    },
    methods: {
        setCookies(selectedTypes) {
            const { secure, sameSite } = this;

            Cookies.set(`${this.consent.prefix}consent`, selectedTypes.join('|'), {
                expires: 730,
                secure,
                sameSite,
            });

            Cookies.set(`${this.consent.prefix}consentOriginal`, this.consentTypes.join('|'), {
                expires: 730,
                secure,
                sameSite,
            });
        },

        toggleConsentBar() {
            document.body.classList.toggle('consent-is-active');
            this.showConsentBar = !this.showConsentBar;
        },

        consentHasOptIn(OptIn) {
            const prefix = this.consent.prefix || '';
            if (typeof Cookies.get(`${prefix}consent`) === 'undefined') return false;

            const consentCookie = Cookies.get(`${prefix}consent`).split(/\|/);

            return consentCookie.includes(OptIn);
        },

        consentAccept() {
            this.setCookies(this.selectedTypes);
            this.toggleConsentBar();

            document.dispatchEvent(new CustomEvent('consentAccepted'));
        },

        consentRefuse() {
            this.setCookies(['functional']);
            this.toggleConsentBar();

            document.dispatchEvent(new CustomEvent('consentRefused'));
        },
    },
};
