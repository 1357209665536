//
//
//
//
//
//
//
//

export default {
    name: 'Button',
    inheritAttrs: false,
    computed: {
        componentType() {
            return this.$attrs.href ? 'a' : 'button';
        },
    },
};
