//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';

export default {
    components: {
        LazyHydrate,
    },
    props: {
        image: {
            type: String,
            default: '',
        },
        imageMobile: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        client: {
            type: String,
            required: true,
        },
        video: {
            type: String,
            default: '',
        },
    },
};
