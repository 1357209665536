//
//
//
//
//
//
//
//
//

export default {
    name: 'Icon',
    props: {
        icon: {
            required: true,
            type: String,
        },
    },
};
