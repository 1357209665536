//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Socials',
    data() {
        return {
            socials: [
                {
                    url: 'https://www.instagram.com/23g.nl/',
                    title: 'Instagram',
                    icon: 'instagram',
                },
                {
                    url: 'https://www.linkedin.com/company/23g/',
                    title: 'LinkedIn',
                    icon: 'linkedin',
                },
            ],
        };
    },
};
