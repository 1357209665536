import { render, staticRenderFns } from "./error.vue?vue&type=template&id=06ea947c&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./error.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/app/components/ui/icon.vue').default,PageHeader: require('/app/components/page/header.vue').default,ConsentBar: require('/app/components/consent-bar.vue').default})
