import { render, staticRenderFns } from "./toggle.vue?vue&type=template&id=61ce944e&"
import script from "./toggle.vue?vue&type=script&lang=js&"
export * from "./toggle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/app/components/ui/icon.vue').default})
