import { ExpoScaleEase } from 'gsap/EasePack';
import { gsap } from 'gsap';
import { ObserveVisibility } from 'vue-observe-visibility';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Vue from 'vue';

import resolveConfig from 'tailwindcss/resolveConfig'; // eslint-disable-line
import tailwindConfig from '../tailwind.config';

gsap.registerPlugin(ExpoScaleEase);
gsap.registerPlugin(ScrollTrigger);

const tailwindScreens = resolveConfig(tailwindConfig).theme.screens;

Object.keys(tailwindScreens).forEach(
    (item) => (tailwindScreens[item] = parseFloat(tailwindScreens[item])),
);

const mixin = {
    data() {
        return {
            breakpoints: tailwindScreens,
        };
    },
    mounted() {
        // isTouchDevice is set globally in ./plugins.js
        this.isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
    },
};

Vue.mixin(mixin);
Vue.directive('observe-visibility', ObserveVisibility);
