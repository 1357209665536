//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CaseList',
    props: {
        title: {
            default: '',
            type: String,
        },
        list: {
            required: true,
            type: Array,
        },
    },
};
