//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UiImg',
    props: {
        delay: {
            type: Number,
            default: 0,
        },
        src: {
            required: true,
            type: String,
        },
        alt: {
            required: true,
            type: String,
        },
    },
    data: () => ({
        lazyLoaded: false,
    }),
    mounted() {
        this.$refs.image.addEventListener('load', this.handleResponse, { once: true });
        this.$refs.image.addEventListener('error', this.handleResponse, { once: true });
    },
    methods: {
        handleResponse() {
            setTimeout(() => {
                this.lazyLoaded = true;
                document.dispatchEvent(new CustomEvent('lazyloaded'));
            }, this.delay);
        },
    },
};
