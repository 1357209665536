import Vue from 'vue';

import CaseContent from '@/components/case/content.vue';
import CaseCta from '@/components/case/cta.vue';
import CaseIntro from '@/components/case/intro.vue';
import CaseList from '@/components/case/list.vue';
import CaseRelated from '@/components/case/related.vue';
import CaseVideo from '@/components/case/video.vue';
import LogoSmall from '@/components/ui/logo-small.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiImg from '@/components/ui/image.vue';
import UiImgEnhanced from '@/components/ui/image-enhanced.vue';
import UiSocials from '@/components/ui/socials.vue';
import UiToggle from '@/components/ui/toggle.vue';
import UiTransition from '@/components/ui/transition.vue';

Vue.component('CaseContent', CaseContent);
Vue.component('CaseCta', CaseCta);
Vue.component('CaseIntro', CaseIntro);
Vue.component('CaseList', CaseList);
Vue.component('CaseRelated', CaseRelated);
Vue.component('CaseVideo', CaseVideo);
Vue.component('LogoSmall', LogoSmall);
Vue.component('UiIcon', UiIcon);
Vue.component('UiImg', UiImg);
Vue.component('UiImgEnhanced', UiImgEnhanced);
Vue.component('UiSocials', UiSocials);
Vue.component('UiToggle', UiToggle);
Vue.component('UiTransition', UiTransition);
