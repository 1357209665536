//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UiImgEnhanced',
    inheritAttrs: false,
    props: {
        alt: {
            required: true,
            type: String,
        },
        src: {
            required: true,
            type: String,
        },
    },
    data: () => ({
        lazyLoaded: false,
        srcWebp: null,
    }),
    created() {
        this.srcWebp = this.src.replace(/\.(png|jpg|jpeg|gif)$/, '.webp');
    },
    mounted() {
        this.$refs.image.addEventListener('load', this.handleResponse, { once: true });
        this.$refs.image.addEventListener('error', this.handleResponse, { once: true });
    },
    methods: {
        handleResponse() {
            this.lazyLoaded = true;
            document.dispatchEvent(new CustomEvent('lazyloaded'));
        },
    },
};
