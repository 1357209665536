//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CaseVideo',
    props: {
        video: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isStarted: false,
        };
    },
};
