import { render, staticRenderFns } from "./cases.vue?vue&type=template&id=e42f6866&scoped=true&"
import script from "./cases.vue?vue&type=script&lang=js&"
export * from "./cases.vue?vue&type=script&lang=js&"
import style0 from "./cases.vue?vue&type=style&index=0&id=e42f6866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e42f6866",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/app/components/ui/icon.vue').default,UiTransition: require('/app/components/ui/transition.vue').default})
