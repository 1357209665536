//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from 'gsap';
import { mapMutations, mapState } from 'vuex';

export default {
    data() {
        return {
            timeline: null,
            locationInFocus: null,
        };
    },
    computed: {
        ...mapState('locations', ['locations']),
    },
    mounted() {
        this.$nuxt.$on('hideMenu', this.hide);

        this.setupAnimation();
        this.show();

        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        this.$nuxt.$off('hideMenu');
        document.body.classList.remove('js-menu-open');
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        ...mapMutations('menu', ['hideMenu', 'setIsMenuBusy']),

        show() {
            this.setIsMenuBusy(true);
            this.timeline.play();
        },

        hide() {
            this.hideMenu();
        },

        setupAnimation() {
            // calculate the screen filling diameter
            const follower = document.getElementById('menu').getBoundingClientRect();
            const diameterValue = Math.sqrt(window.innerHeight ** 2 + window.innerWidth ** 2) * 2;

            this.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.setIsMenuBusy(false);

                    document.body.classList.add('js-menu-open');
                },
                onReverseComplete: () => {
                    this.setIsMenuBusy(false);
                    this.hideMenu();

                    document.body.classList.remove('js-menu-open');
                },
            });

            gsap.set(this.$refs.navCircle, {
                scaleX: 0,
                scaleY: 0,
                width: diameterValue,
                height: diameterValue,
                left: follower.x - diameterValue / 2 + follower.width / 2,
                top: follower.y - diameterValue / 2 + follower.height / 2,
            });

            this.timeline.to(this.$refs.navCircle, 0.35, {
                scaleX: 1,
                scaleY: 1,
                ease: 'expoScale(0.3, 1)',
            });

            this.timeline.to([this.$refs.menu, this.$refs.locations, this.$refs.contact], 0.25, {
                autoAlpha: 1,
            });
        },

        handleResize() {
            const diameterValue = Math.sqrt(window.innerHeight ** 2 + window.innerWidth ** 2) * 2;
            const follower = document.getElementById('menu').getBoundingClientRect();

            gsap.set(this.$refs.navCircle, {
                width: diameterValue,
                height: diameterValue,
                left: follower.x - diameterValue / 2 + follower.width / 2,
                top: follower.y - diameterValue / 2 + follower.height / 2,
            });
        },
    },
};
