//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from 'gsap';
import LazyHydrate from 'vue-lazy-hydration';

export default {
    name: 'UiTransition',
    components: {
        LazyHydrate,
    },
    props: {
        flip: {
            type: Boolean,
            default: false,
        },
        once: {
            type: Boolean,
            default: true,
        },
        throttle: {
            type: Number,
            default: 0,
        },
        threshold: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        visibilityChanged(isVisible, entry) {
            if (!isVisible) return;

            if (this.flip) {
                gsap.to(entry.target, 0.6, {
                    autoAlpha: 1,
                    rotationY: -180,
                });

                gsap.to(entry.target, 0.6, {
                    y: 0,
                });

                return;
            }

            gsap.to(entry.target, 0.3, {
                autoAlpha: 1,
            });
        },
    },
};
