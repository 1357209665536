//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';

export default {
    name: 'Index',
    components: {
        LazyHydrate,
        homeCases: () => import('~/components/home/cases.vue'),
    },
    scrollToTop: true,
    head() {
        return {
            meta: [
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.$t('title'),
                },
            ],
            title: this.$t('title'),
        };
    },
};
