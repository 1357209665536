//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
    name: 'CaseRelated',
    props: {
        currentCaseName: {
            required: true,
            type: String,
        },
    },
    computed: {
        ...mapState('cases', ['cases']),

        currentCase() {
            const casesLocale = this.cases[this.$i18n.locale];
            const currentIndex = casesLocale.findIndex(
                (caseItem) => caseItem.key === this.currentCaseName,
            );

            // If the current case is the last one, return the first one
            if (currentIndex === casesLocale.length - 1) {
                return casesLocale[0];
            }

            return casesLocale[currentIndex + 1];
        },
    },
};
