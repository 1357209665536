const state = () => ({
    cases: {
        nl: [
            {
                key: 'student_doctors',
                title: 'The Student Doctors',
                subtitle: 'Chatten met je huisarts.',
                image: '/images/cases/case-student-doctors.webp',
                image_mobile: '/images/cases/_mobile/case-student-doctors.webp',
                link: 'werk-student-doctors',
                hero: '/images/cases/student-doctors/hero.webp',
            },
            {
                key: 'ferring',
                title: 'Ferring',
                subtitle: 'Een online coach om je leefstijl te verbeteren.',
                image: '/images/cases/case-ferring.webp',
                image_mobile: '/images/cases/_mobile/case-ferring.webp',
                link: 'werk-ferring',
                hero: '/images/cases/ferring/hero.webp',
            },
            {
                key: 'heerlijk',
                title: 'Heerlijk',
                subtitle: 'Meer dan 1000 toprestaurants in je broekzak.',
                image: '/images/cases/case-heerlijk.webp',
                image_mobile: '/images/cases/_mobile/case-heerlijk.webp',
                link: 'werk-heerlijk',
                hero: '/images/cases/heerlijk/hero.webp',
            },
            {
                key: 'nines',
                title: 'Nines',
                subtitle: 'Je volledige huishouden managen binnen één systeem.',
                image: '/images/cases/case-nines.webp',
                image_mobile: '/images/cases/_mobile/case-nines.webp',
                link: 'werk-nines',
                hero: '/images/cases/nines/hero.webp',
            },
            {
                key: 'jump_the_q',
                title: 'Jumpthe-Q',
                subtitle: 'Altijd vooraan in de rij.',
                image: '/images/cases/case-jump-the-q.webp',
                image_mobile: '/images/cases/_mobile/case-jump-the-q.webp',
                link: 'werk-jump-the-q',
                hero: '/images/cases/jump-the-q/hero.webp',
            },
            {
                key: 'bobbys',
                title: "Bobby's Dry Gin",
                subtitle: 'Van Pinang Raci een begrip maken.',
                image: '/images/cases/case-bobbys.webp',
                image_mobile: '/images/cases/_mobile/case-bobbys.webp',
                link: 'werk-bobbys',
                hero: '/images/cases/bobbys/hero.webp',
            },
            {
                key: 'fagron',
                title: 'Fagron',
                subtitle: 'De toekomst van gepersonaliseerde medicatie digitaliseren.',
                image: '/images/cases/case-fagron.webp',
                image_mobile: '/images/cases/_mobile/case-fagron.webp',
                link: 'werk-fagron',
                hero: '/images/cases/fagron/hero.webp',
            },
            {
                key: 'rembrandt',
                title: 'Mastercard / Rembrandthuis Museum',
                subtitle: 'Wandelen met Rembrandt in Amsterdam.',
                image: '/images/cases/case-rembrandt.webp',
                image_mobile: '/images/cases/_mobile/case-rembrandt.webp',
                link: 'werk-rembrandt',
                hero: '/images/cases/rembrandt/hero.webp',
            },
            {
                key: 'dnzb',
                title: 'DNZB',
                subtitle: 'Een eigen omgeving voor 5.000 professionals in de zorg.',
                image: '/images/cases/case-dnzb.webp',
                image_mobile: '/images/cases/_mobile/case-dnzb.webp',
                link: 'werk-dnzb',
                hero: '/images/cases/dnzb/hero.jpg',
            },
            {
                key: 'belmond',
                title: 'Belmond',
                subtitle: 'Een social AR-campagne voor een internationale hotelketen.',
                image: '/images/cases/case-belmond.webp',
                image_mobile: '/images/cases/_mobile/case-belmond.webp',
                link: 'werk-belmond',
                hero: '/images/cases/belmond/hero.jpg',
            },
            {
                key: 'erasmus_mc',
                title: 'Erasmus MC',
                subtitle: 'Een prijswinnend intranet voor 15.000 medewerkers.',
                image: '/images/cases/case-erasmus.webp',
                image_mobile: '/images/cases/_mobile/case-erasmus.webp',
                link: 'werk-erasmus-mc-intranet',
                hero: '/images/cases/emc/hero.jpg',
            },
            {
                key: 'sonnema',
                title: 'Sonnema1860',
                subtitle: 'Alle flessen slijten in de voorverkoop.',
                image: '/images/cases/case-sonnema.webp',
                image_mobile: '/images/cases/_mobile/case-sonnema.webp',
                link: 'werk-sonnema-1860',
                hero: '/images/cases/sonnema/header.jpg',
            },
            {
                key: 'cbre',
                title: 'CBRE',
                subtitle: 'De Rolls Royce onder de compliance portals.',
                image: '/images/cases/case-cbre.webp',
                image_mobile: '/images/cases/_mobile/case-cbre.webp',
                link: 'werk-cbre',
                hero: '/images/cases/cbre/header.jpg',
            },
            {
                key: 'recirculo',
                title: 'Recirculo',
                subtitle: 'De recyclingindustrie digitaliseren.',
                image: '/images/cases/case-recirculo.webp',
                image_mobile: '/images/cases/_mobile/case-recirculo.webp',
                link: 'werk-recirculo',
                hero: '/images/cases/recirculo/header.jpg',
            },
        ],
        en: [
            {
                key: 'ferring',
                title: 'Ferring',
                subtitle: 'An online coach to improve your lifestyle.',
                image: '/images/cases/case-ferring.webp',
                image_mobile: '/images/cases/_mobile/case-ferring.webp',
                link: 'werk-ferring',
                hero: '/images/cases/ferring/hero.webp',
            },
            {
                key: 'nines',
                title: 'Nines',
                subtitle: "World's first system-of-record for household data.",
                image: '/images/cases/case-nines.webp',
                image_mobile: '/images/cases/_mobile/case-nines.webp',
                link: 'werk-nines',
                hero: '/images/cases/nines/hero.webp',
            },
            {
                key: 'jump_the_q',
                title: 'Jumpthe-Q',
                subtitle: 'Always first in line.',
                image: '/images/cases/case-jump-the-q.webp',
                image_mobile: '/images/cases/_mobile/case-jump-the-q.webp',
                link: 'werk-jump-the-q',
                hero: '/images/cases/jump-the-q/hero.webp',
            },
            {
                key: 'bobbys',
                title: "Bobby's Dry Gin",
                subtitle: 'Making Pinang Raci a household name.',
                image: '/images/cases/case-bobbys.webp',
                image_mobile: '/images/cases/_mobile/case-bobbys.webp',
                link: 'werk-bobbys',
                hero: '/images/cases/bobbys/hero.webp',
            },
            {
                key: 'fagron',
                title: 'Fagron',
                subtitle: 'Digitizing the future of personalized medicine.',
                image: '/images/cases/case-fagron.webp',
                image_mobile: '/images/cases/_mobile/case-fagron.webp',
                link: 'werk-fagron',
                hero: '/images/cases/fagron/hero.webp',
            },
            {
                key: 'rembrandt',
                title: 'Mastercard / Rembrandt House Museum',
                subtitle: 'Walking with Rembrandt in Amsterdam.',
                image: '/images/cases/case-rembrandt.webp',
                image_mobile: '/images/cases/_mobile/case-rembrandt.webp',
                link: 'werk-rembrandt',
                hero: '/images/cases/rembrandt/hero.webp',
            },
            {
                key: 'dnzb',
                title: 'DNZB',
                subtitle: 'A private online platform for 5,000 healthcare professionals.',
                image: '/images/cases/case-dnzb.webp',
                image_mobile: '/images/cases/_mobile/case-dnzb.webp',
                link: 'werk-dnzb',
                hero: '/images/cases/dnzb/hero.jpg',
            },
            {
                key: 'belmond',
                title: 'Belmond',
                subtitle: 'Travel the world during lockdown.',
                image: '/images/cases/case-belmond.webp',
                image_mobile: '/images/cases/_mobile/case-belmond.webp',
                link: 'werk-belmond',
                hero: '/images/cases/belmond/hero.jpg',
            },
            {
                key: 'erasmus_mc',
                title: 'Erasmus MC',
                subtitle: 'An award-winning intranet for 15,000 employees.',
                image: '/images/cases/case-erasmus.webp',
                image_mobile: '/images/cases/_mobile/case-erasmus.webp',
                link: 'werk-erasmus-mc-intranet',
                hero: '/images/cases/emc/hero.jpg',
            },
            {
                key: 'sonnema',
                title: 'Sonnema1860',
                subtitle: 'Selling out all bottles during presale.',
                image: '/images/cases/case-sonnema.webp',
                image_mobile: '/images/cases/_mobile/case-sonnema.webp',
                link: 'werk-sonnema-1860',
                hero: '/images/cases/sonnema/header.jpg',
            },
            {
                key: 'cbre',
                title: 'CBRE',
                subtitle: 'The rolls royce among compliance portals.',
                image: '/images/cases/case-cbre.webp',
                image_mobile: '/images/cases/_mobile/case-cbre.webp',
                link: 'werk-cbre',
                hero: '/images/cases/cbre/header.jpg',
            },
        ],
    },
});

export default {
    state,
};
