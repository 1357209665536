//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from 'gsap';
import { mapState, mapMutations } from 'vuex';

import Logo from '@/components/layout/logo.vue';
import pages from '../../pages';

export default {
    components: {
        Logo,
    },
    props: {
        isFixed: {
            type: Boolean,
            default: true,
        },
        headerButtons: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isClosing: false,
        langSwitchIsOpen: false,
    }),
    computed: {
        ...mapState('menu', ['isMenuOpen', 'isMenuBusy']),

        languagesMenu() {
            return ['nl', 'en'].filter((item) => item !== this.$i18n.locale);
        },

        hasMultipleTranslations() {
            if (!this.currentPagePaths) return false;

            return Object.keys(this.currentPagePaths).length > 1;
        },

        currentPagePaths() {
            let paths = null;

            Object.keys(pages).forEach((key) => {
                const page = pages[key];

                if (page[this.$i18n.locale] === this.$route.path) {
                    paths = page;
                }
            });

            return paths;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapMutations('menu', ['showMenu']),

        handleScroll() {
            if (!this.isFixed) return;

            if (window.pageYOffset > window.innerHeight / 4) {
                gsap.to(this.$refs.headerbg, {
                    opacity: 1,
                });

                return;
            }

            gsap.to(this.$refs.headerbg, {
                opacity: 0,
            });
        },

        toggleMenu() {
            if (this.isMenuOpen) {
                this.$nuxt.$emit('hideMenu');
                this.isClosing = true;
                return;
            }

            this.isClosing = false;
            this.langSwitchIsOpen = false;
            this.showMenu();
        },

        handleLogoClick() {
            if (this.isMenuOpen) {
                this.$nuxt.$emit('hideMenu');
                this.isClosing = true;
                return;
            }

            this.isClosing = false;
            this.langSwitchIsOpen = false;
        },

        getLangSwitchHref(language) {
            let href = '';

            if (Object.prototype.hasOwnProperty.call(this.currentPagePaths, language)) {
                href = `https://${this.$nuxt.$config[`DOMAIN_${language.toUpperCase()}`]}${
                    this.currentPagePaths[language]
                }`;
            }

            return href;
        },
    },
};
