//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CaseContent',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
