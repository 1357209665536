var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"cases",staticClass:"entry md:gap-0 relative grid w-full gap-12 overflow-x-hidden"},[_vm._l((_vm.cases[_vm.$i18n.locale]),function(item,index){return _c('article',{key:index,staticClass:"bg-dark md:bg-transparent bg-opacity-75"},[_c('ui-transition',{staticClass:"md:h-screen flex items-center"},[_c('nuxt-link',{staticClass:"flex items-center w-full h-full",attrs:{"to":_vm.localePath(item.link)}},[_c('div',{staticClass:"case-content w-full"},[_c('figure',{staticClass:"aspect-w-4 aspect-h-3 md:hidden w-full mb-2"},[_c('ui-img-enhanced',{staticClass:"absolute top-0 left-0 object-cover w-full h-full",attrs:{"src":item.image_mobile,"width":"327","height":"245","alt":item.title}})],1),_vm._v(" "),_c('h2',{class:[
                            'md:mb-4 text-gray leading-relaxed md:text-2.5xl md:leading-relaxed',
                            {
                                set: index === 0,
                            } ]},[_vm._v("\n                        "+_vm._s(item.title)+"\n                    ")]),_vm._v(" "),_c('h3',{class:[
                            'text-3xl lg:text-5xl font-heading uppercase font-extrabold leading-none mb-3 md:mb-6',
                            {
                                set: index === 0,
                            } ]},[_vm._v("\n                        "+_vm._s(item.subtitle)+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start"},[_c('span',{class:[
                                'lg:text-2xl bg-primary font-heading flex-inline md:mr-auto mr-3 lg:mr-6 px-6 py-2 text-xl italic font-medium text-white uppercase md:mb-4 lg:mb-0',
                                {
                                    set: index === 0,
                                } ]},[_vm._v("\n                            "+_vm._s(_vm.$t('tagline'))+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"cta-link md:mt-6 mt-4"},[_vm._v("\n                            "+_vm._s(_vm.$t('case.cta'))+"\n                            "),_c('ui-icon',{staticClass:"ml-2 mt-0.5 text-lg",attrs:{"icon":"arrow"}})],1)])])])],1)],1)}),_vm._v(" "),_c('div',{class:[
            'case-canvas-outer hidden md:block absolute top-0 left-0 w-full h-screen pointer-events-none',
            { pinned: _vm.pinCanvas, ready: _vm.isPinCanvasReady } ]},[_c('div',{staticClass:"entry relative h-screen"},[_c('div',{staticClass:"case-canvas-inner top-1/2 right-6 xl:right-12 absolute transform -translate-y-1/2"},[_c('div',{staticClass:"aspect-w-4 aspect-h-3"},[_c('canvas',{ref:"canvas",staticClass:"case-canvas absolute top-0 left-0"})])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }