var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'absolute lg:static w-full': !_vm.isFixed }},[_c('header',{staticClass:"header h-header-sm md:h-header z-60 relative"},[_c('div',{class:[{ fixed: _vm.isFixed }, 'top-0 left-0 w-full']},[_c('div',{staticClass:"entry md:py-6 flex items-center py-5"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('index'),"aria-label":"logo"},nativeOn:{"click":function($event){return _vm.handleLogoClick.apply(null, arguments)}}},[_c('logo')],1),_vm._v(" "),(_vm.headerButtons)?_c('div',{staticClass:"header-controls font-body relative z-10 flex items-center ml-auto"},[(_vm.hasMultipleTranslations)?_c('div',{staticClass:"md:mr-8 relative flex flex-col -mr-4"},[_c('button',{staticClass:"flex items-center p-2 mt-1 uppercase",on:{"click":function($event){$event.preventDefault();_vm.langSwitchIsOpen = !_vm.langSwitchIsOpen}}},[_vm._v("\n                            "+_vm._s(_vm.$i18n.locale)+"\n\n                            "),_c('span',{class:[{ open: _vm.langSwitchIsOpen }, 'lang-icon ml-2']})]),_vm._v(" "),_c('ul',{class:[
                                { hidden: !_vm.langSwitchIsOpen },
                                'absolute top-full left-0 flex flex-col' ]},_vm._l((_vm.languagesMenu),function(language){return _c('li',{key:language},[_c('a',{staticClass:"hover:text-primary p-2 uppercase transition-colors",attrs:{"href":_vm.getLangSwitchHref(language)}},[_vm._v("\n                                    "+_vm._s(language)+"\n                                ")])])}),0)]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"md:text-lg md:block hover-underline hidden",attrs:{"href":("mailto:" + (_vm.$t('email')))}},[_vm._v("\n                        "+_vm._s(_vm.$t('email'))+"\n                    ")]),_vm._v(" "),_c('button',{staticClass:"menu-outer focus:outline-none ml-8 -mr-3",attrs:{"id":"menu","aria-expanded":_vm.isMenuOpen,"disabled":_vm.isMenuBusy,"aria-label":"Main Menu","data-pin-trigger":""},on:{"click":_vm.toggleMenu}},[_c('div',{class:[
                                'menu',
                                {
                                    opened: _vm.isMenuOpen && !_vm.isClosing,
                                } ]},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]),_vm._v(" "),_c('div',{ref:"menuCircle",staticClass:"menu-circle"})])]):_vm._e()],1)])]),_vm._v(" "),_c('div',{ref:"headerbg",class:[
            _vm.isFixed ? 'fixed' : 'absolute',
            "\n                bg-dark\n                h-header-sm\n                md:h-header\n                backdrop-filter backdrop-blur-sm\n                bg-opacity-80\n                top-0\n                left-0\n                z-40\n                w-full\n                opacity-0\n            " ]})])}
var staticRenderFns = []

export { render, staticRenderFns }