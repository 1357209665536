const state = () => ({
    elleke: {
        name: 'Elleke',
        email: 'elleke@23g.nl',
        image: '/images/cta/elleke.webp',
        phone: {
            number: '+310103072681',
            formatted: '+31 (0)10 307 26 81',
        },
    },
    jack: {
        name: 'Jack',
        email: 'jack@23g.nl',
        image: '/images/cta/jack.webp',
        phone: {
            number: '+310103072681',
            formatted: '+31 (0) 103 07 26 81',
        },
    },
    michiel: {
        name: 'Michiel',
        email: 'michiel@23g.nl',
        image: '/images/cta/michiel.webp',
        phone: {
            number: '+310103073687',
            formatted: '+31 (0) 10 307 36 87',
        },
    },
    robert: {
        name: 'Robert',
        email: 'robert@23g.nl',
        image: '/images/cta/robert.webp',
        phone: {
            number: '+310103071292',
            formatted: '+31 (0) 10 307 12 92',
        },
    },
    tobias: {
        name: 'Tobias',
        email: 'tobias@23g.nl',
        image: '/images/cta/tobias.webp',
        phone: {
            number: '+310103071292',
            formatted: '+31 (0) 10 307 12 92',
        },
    },
});

export default {
    state,
};
