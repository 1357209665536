import { render, staticRenderFns } from "./transition.vue?vue&type=template&id=3d552990&scoped=true&"
import script from "./transition.vue?vue&type=script&lang=js&"
export * from "./transition.vue?vue&type=script&lang=js&"
import style0 from "./transition.vue?vue&type=style&index=0&id=3d552990&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d552990",
  null
  
)

export default component.exports