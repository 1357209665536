module.exports = {
    corePlugins: {
        container: false,
    },
    plugins: [require('@tailwindcss/aspect-ratio'), require('@tailwindcss/line-clamp')],
    purge: {
        content: [
            `assets/**/*.scss`,
            `components/**/*.{vue,js}`,
            `layouts/**/*.vue`,
            `nuxt.config.{js,ts}`,
            `pages/**/*.vue`,
            `plugins/**/*.{js,ts}`,
        ],
    },
    theme: {
        extend: {
            aspectRatio: {
                18: '18',
                24: '24',
                25: '25',
                29: '29',
                39: '39',
                40: '40',
                46: '46',
                57: '57',
                67: '67',
                80: '80',
                117: '117',
                119: '119',
                145: '145',
                184: '184',
                400: '400',
                441: '441',
                617: '617',
            },
            colors: {
                'dark-blue': '#0D0B14',
                'dark-gray': '#2F2D2D',
                'gray-medium': '#696868',
                'gray-secondary': '#5F5F5F',
                'heerlijk-dark': '#010003',
                'jump-the-q': '#00243d',
                'jump-the-q-dark': '#090713',
                'nines-dark': '#605c54',
                bobbys: '#232323',
                cbre: '#166a4d',
                cinder: {
                    50: '#f7f6f9',
                    100: '#edecf2',
                    200: '#d7d4e3',
                    300: '#b4afca',
                    400: '#8a83ad',
                    500: '#6b6394',
                    600: '#584f7a',
                    700: '#484163',
                    800: '#3e3854',
                    900: '#17151e',
                },
                dark: '#0f0e14',
                dnzb: '#103453',
                gray: {
                    DEFAULT: '#a1a1a1',
                    50: '#f0f0f0',
                    100: '#e3e3ea',
                    200: '#cbcbd6',
                    300: '#a8a7b9',
                    400: '#7c7b95',
                    500: '#62607a',
                    600: '#545268',
                    700: '#494757',
                    800: '#413f4b',
                    900: '#17151E',
                },
                light: '#e9e9e9',
                nines: '#dfdacd',
                outline: '#5E9ED6',
                primary: '#e51c2a',
                recirculo: '#5482c2',
                red: '#e61728',
                romours: '#1A181E',
                slate: {
                    DEFAULT: '#222125',
                    50: '#f6f6f7',
                    100: '#e2e2e5',
                    200: '#c4c4cb',
                    300: '#a09fa9',
                    400: '#7d7b86',
                    500: '#61606c',
                    600: '#4d4c55',
                    700: '#403f46',
                    800: '#36353a',
                    900: '#302f32',
                },
                snowglobe: '#ce2b4a',
                yellow: {
                    50: '#fefbec',
                    100: '#fbf3ca',
                    200: '#f8e88f',
                    300: '#f4d755',
                    400: '#f2c940',
                    500: '#eaa516',
                    600: '#cf7f10',
                    700: '#ac5c11',
                    800: '#8c4814',
                    900: '#733b14',
                },
            },
            borderWidth: {
                '3px': '3px',
            },
            fontFamily: {
                body: ['Barlow', 'sans-serif'],
                heading: ['Barlow Condensed', 'sans-serif'],
                serif: ['Petrona', 'serif'],
            },
            fontSize: {
                '23G-sm': '1.0625rem',
                '23G': '1.4375rem',
                '2.5xl': '1.6875rem',
                '3.5xl': '2rem',
            },
            screens: {
                sm: '414px',
                '2xl': '1450px',
                '2xl-xl': '1546px',
                '3xl': '1750px',
                '3xl-xl': '1846px',
            },
            width: {
                '2px': '2px',
            },
            height: {
                'header-sm': 'var(--header-height-sm)',
                header: 'var(--header-height)',
            },
            maxWidth: {
                'content-sm': 850,
                'content-lg': 1100,
            },
            minHeight: {
                'screen-min-header-sm': 'calc(100vh - var(--header-height-sm))',
                'screen-min-header': 'calc(100vh - var(--header-height))',
            },
            gridTemplateRows: {
                12: 'repeat(12, minmax(0, 1fr))',
            },
            gridRow: {
                'span-7': 'span 7 / span 7',
                'span-8': 'span 8 / span 8',
                'span-9': 'span 9 / span 9',
                'span-10': 'span 10 / span 10',
                'span-11': 'span 11 / span 11',
                'span-12': 'span 12 / span 12',
            },
            zIndex: {
                60: 60,
            },
            transitionDuration: {
                DEFAULT: '300ms',
            },
        },
    },
    variants: {
        extend: {
            margin: ['last'],
        },
    },
};
