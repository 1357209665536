//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';

import LayoutFooter from '@/components/layout/footer.vue';
import LayoutHeader from '@/components/layout/header.vue';
import Navbar from '@/components/layout/navbar.vue';
import ConsentBar from '@/components/consent-bar.vue';

export default {
    components: {
        ConsentBar,
        LayoutFooter,
        LayoutHeader,
        LazyHydrate,
        Navbar,
    },
    data() {
        return {
            routeName: this.$route.name,
        };
    },
    head() {
        const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

        return {
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('description'),
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.$config.BASE_URL + this.$t('og_image'),
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.$t('title'),
                },
                {
                    hid: 'og:type',
                    property: 'og:type',
                    content: 'website',
                },
                ...i18nHead.meta,
            ],
        };
    },
    computed: {
        ...mapState('menu', ['isMenuOpen']),
    },
    watch: {
        $route(value) {
            this.routeName = value.name;
            this.hideMenu();

            if (window.leadinfo) {
                window.leadinfo('trackPageView');
            }
        },
    },
    methods: {
        ...mapMutations('menu', ['hideMenu']),
    },
};
