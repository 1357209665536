//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UiToggle',
    props: {
        open: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            isOpen: this.open,
        };
    },
};
