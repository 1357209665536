var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        ("page-body page-" + (_vm.$route.name) + " min-h-screen w-full flex flex-col"),
        {
            'bg-jump-the-q-dark': _vm.routeName && _vm.routeName.includes('jump-the-q'),
            'bg-heerlijk-dark': _vm.routeName && _vm.routeName.includes('heerlijk'),
            'bg-dark':
                _vm.routeName &&
                !_vm.routeName.includes('jump-the-q') &&
                !_vm.routeName.includes('heerlijk'),
        } ]},[_c('lazy-hydrate',{attrs:{"when-idle":""}},[_c('layout-header',{attrs:{"is-fixed":!['/snowglobe/', '/beker/', '/eenden/', '/paarden/'].includes(_vm.$route.path)}})],1),_vm._v(" "),(_vm.isMenuOpen)?_c('navbar'):_vm._e(),_vm._v(" "),_c('Nuxt',{staticClass:"flex-1"}),_vm._v(" "),_c('lazy-hydrate',{attrs:{"when-visible":""}},[(!['/contact/', '/facilion/'].includes(_vm.$route.path))?_c('layout-footer'):_vm._e()],1),_vm._v(" "),_c('lazy-hydrate',{attrs:{"when-visible":""}},[_c('consent-bar')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }