const state = () => ({
    isMenuOpen: false,
    isMenuBusy: false,
});

const mutations = {
    showMenu(state) {
        state.isMenuOpen = true;
    },
    hideMenu(state) {
        state.isMenuOpen = false;
    },
    setIsMenuBusy(state, value) {
        state.isMenuBusy = value;
    },
};

export default {
    state,
    mutations,
};
