//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
    name: 'CaseCta',
    props: {
        contact: {
            type: String,
            default: 'tobias',
        },
        subtitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState(['contacts']),

        currentContact() {
            return this.contacts[this.contact];
        },
    },
};
