var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lazy-hydrate',{attrs:{"when-visible":""}},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
            callback: _vm.visibilityChanged,
            intersection: {
                threshold: _vm.threshold,
            },
            once: _vm.once,
            throttle: _vm.throttle,
        }),expression:"{\n            callback: visibilityChanged,\n            intersection: {\n                threshold,\n            },\n            once,\n            throttle,\n        }"}],class:[{ flip: _vm.flip }, 'opacity-0']},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }